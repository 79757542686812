import React, { useState, useEffect, useRef } from 'react';

const matrixChars = '01ABCDEFGHIJKLMNOPQRSTUVWXYZ!@#$%^&*()_+-=[]{}|;:,./<>?';

export const MatrixText = ({ text, className = '', interval = 25 }) => {
  const [displayText, setDisplayText] = useState(text);
  const intervalRef = useRef(null);
  
  useEffect(() => {
    let chars = text.split('');
    let iterations = 0;
    
    intervalRef.current = setInterval(() => {
      const newText = chars.map((char, index) => {
        // 保持空格不变
        if (char === ' ') return ' ';
        // 已完成转换的字符保持不变
        if (index < iterations) return char;
        // 随机选择一个矩阵字符
        return matrixChars[Math.floor(Math.random() * matrixChars.length)];
      }).join('');
      
      setDisplayText(newText);
      iterations += 1/2;
      
      if (iterations >= text.length * 2) {
        clearInterval(intervalRef.current);
      }
    }, interval);
    
    return () => clearInterval(intervalRef.current);
  }, [text, interval]);
  
  return (
    <span 
      className={`tracking-wide ${className}`} 
      style={{
        fontFamily: "'Orbitron', 'JetBrains Mono', monospace",
        textShadow: '0 0 2px currentColor'
      }}>
      {displayText}
    </span>
  );
};

export const ScanlineText = ({ text, className = '' }) => {
  return (
    <div className="relative inline-block">
      <span className={`relative z-10 ${className}`} 
        style={{
          textShadow: '0 0 2px currentColor',
          fontFamily: "'Orbitron', monospace",
        }}>
        {text}
      </span>
      <div className="absolute inset-0 scanline-effect" 
        style={{
          background: `
            linear-gradient(transparent 0%, 
              rgba(255, 255, 255, 0.15) 10%, 
              transparent 15%,
              transparent 85%,
              rgba(255, 255, 255, 0.15) 90%,
              transparent 100%)
          `,
          animation: 'scanline-move 2s linear infinite',
          zIndex: 1,
          pointerEvents: 'none',
          opacity: 0.4,
        }} 
      />
      <div className="absolute inset-0"
        style={{
          background: 'repeating-linear-gradient(0deg, rgba(255,255,255,0.15) 0px, transparent 1px, transparent 2px)',
          backgroundSize: '100% 3px',
          opacity: 0.3,
          pointerEvents: 'none',
        }}
      />
    </div>
  );
};

// 可选的打字机效果
export const TypewriterText = ({ text, className = '', speed = 20 }) => {
  const [displayText, setDisplayText] = useState('');
  const [isComplete, setIsComplete] = useState(false);
  const [showCursor, setShowCursor] = useState(true);
  const timeoutRef = useRef(null);
  const cursorIntervalRef = useRef(null);
 
  useEffect(() => {
    cursorIntervalRef.current = setInterval(() => {
      setShowCursor(prev => !prev);
    }, 400);
    return () => clearInterval(cursorIntervalRef.current);
  }, []);
 
  useEffect(() => {
    let index = 0;
    const type = () => {
      if (index <= text?.length) {
        setDisplayText(text?.slice(0, index) || '');
        index++;
        timeoutRef.current = setTimeout(type, Math.random() * speed + speed/2);
      } else {
        setIsComplete(true);
      }
    };
    
    setIsComplete(false);
    type();
    return () => clearTimeout(timeoutRef.current);
  }, [text, speed]);
 
  return (
    <div className={`relative inline-block font-mono min-h-[1.2em] ${className}`}>
      {displayText || '\u00A0'}
      {text && !isComplete && showCursor && (
        <span className="inline-block w-[2px] h-[1.1em] bg-current align-middle ml-[1px]" />
      )}
    </div>
  );
};

// 可选的故障效果
export const GlitchText = ({ text, className = '' }) => {
  const [glitchText, setGlitchText] = useState(text);
  const glitchChars = '!@#$%^&*()_+-=[]{}|;:,.<>?';

  useEffect(() => {
    let glitchInterval;
    let resetTimeout;

    const startGlitch = () => {
      glitchInterval = setInterval(() => {
        const glitchedText = text.split('').map(char => 
          Math.random() < 0.1 ? glitchChars[Math.floor(Math.random() * glitchChars.length)] : char
        ).join('');
        setGlitchText(glitchedText);
      }, 50);

      resetTimeout = setTimeout(() => {
        clearInterval(glitchInterval);
        setGlitchText(text);
      }, 500);
    };

    const glitchTimer = setInterval(startGlitch, 3000);
    return () => {
      clearInterval(glitchTimer);
      clearInterval(glitchInterval);
      clearTimeout(resetTimeout);
    };
  }, [text]);

  return (
    <span className={`relative inline-block ${className}`}>
      <span className="relative z-10">{glitchText}</span>
      <span className="absolute top-0 left-0 -translate-x-1 text-red-500 opacity-30 z-0">{glitchText}</span>
      <span className="absolute top-0 left-0 translate-x-1 text-blue-500 opacity-30 z-0">{glitchText}</span>
    </span>
  );
};
