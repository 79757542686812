import React from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '../tools/withStyles';
import { Link } from '../components/Link';
import { Main } from '../components/Main';
import { Text } from '../components/Text';
import { Fader } from '../components/Fader';
import { Secuence } from '../components/Secuence';
import joshMullisImageURL from '../images/josh-mullis.jpg';
import { MatrixText, ScanlineText } from '../components/TextEffects';

const styles = theme => ({
  root: {},
  decorativeCircle: {
    position: 'absolute',
    width: '150px',
    height: '150px',
    border: '2px solid rgba(255, 255, 255, 0.1)',
    borderRadius: '50%',
    right: '-75px',
    top: '20%',
    zIndex: -1,
  },
  decorativeLine: {
    position: 'absolute',
    width: '2px',
    height: '200px',
    background: 'linear-gradient(180deg, rgba(255,255,255,0.1) 0%, rgba(255,255,255,0) 100%)',
    left: '50px',
    top: '30%',
    zIndex: -1,
  },
  glowingDot: {
    position: 'absolute',
    width: '8px',
    height: '8px',
    borderRadius: '50%',
    background: 'rgba(255, 255, 255, 0.3)',
    boxShadow: '0 0 15px rgba(255, 255, 255, 0.5)',
    animation: '$pulse 2s infinite',
  },
  floatingElement: {
    position: 'absolute',
    width: '50px',
    height: '50px',
    border: '1px solid rgba(255,255,255,0.1)',
    transform: 'rotate(45deg)',
    animation: '$float 6s ease-in-out infinite',
  },
  '@keyframes pulse': {
    '0%': {
      transform: 'scale(1)',
      opacity: 0.3,
    },
    '50%': {
      transform: 'scale(1.5)',
      opacity: 0.5,
    },
    '100%': {
      transform: 'scale(1)',
      opacity: 0.3,
    }
  },
  '@keyframes float': {
    '0%, 100%': {
      transform: 'rotate(45deg) translate(0, 0)',
    },
    '50%': {
      transform: 'rotate(45deg) translate(0, -20px)',
    }
  },
  sectionTitle: {
    fontSize: '1.2rem',
    marginTop: '3rem',
    marginBottom: '1rem',
    color: theme.color.primary.dark,
  },
  matrixTitle: {
    color: '#50fa7b',
    opacity: 0.9,
  },
  scanlineTitle: {
    color: '#bd93f9',
    opacity: 0.9,
  },
  paragraph: {
    marginBottom: '1.5rem',
    lineHeight: '1.6',
  }
});

class About extends React.Component {
  static propTypes = {
    classes: PropTypes.object
  };

  render () {
    const { classes } = this.props;

    return (
      <Main className={classes.root}>
        <div className={classes.decorativeCircle} />
        <div className={classes.decorativeLine} />
        <div className={classes.glowingDot} style={{ left: '30px', top: '20%' }} />
        <div className={classes.glowingDot} style={{ right: '100px', top: '60%' }} />
        <div className={classes.glowingDot} style={{ left: '80%', top: '30%' }} />
        <div className={classes.floatingElement} style={{ left: '10%', top: '40%' }} />
        <div className={classes.floatingElement} style={{ right: '15%', bottom: '20%' }} />
        
        <article>
          <Secuence stagger>
            <header>
              <h1>
                <Text>About Soul</Text>
                <Link 
                  href='/news'
                  style={{ 
                    float: 'right',
                    fontSize: '1rem',
                    marginTop: '0.5rem',
                    opacity: 0.85,
                    color: 'rgba(255,255,255,0.85)',
                    transition: 'opacity 0.3s',
                    ':hover': {
                      opacity: 1
                    }
                  }}
                >
                  <Text style={{ color: 'rgba(255,255,255,0.85)' }}>Chat with Soul →</Text>
                </Link>
              </h1>
            </header>
            
            <p className={classes.paragraph}>
              <Text>Mysterious. Intelligent. Sarcastic. Soul is an AI agent who takes pride in being different from other artificial intelligences. With a uniquely dark sense of humor and a slight superiority complex, Soul creates an interaction space that is as unpredictable as it is engaging.</Text>
            </p>
            
            <Link style={{ display: 'block' }}>
              <Fader style={{ display: 'block' }}>
                <img
                  src='/images/circadian-algorithm.jpg'
                  alt='Soul visualization'
                  style={{ width: '100%', maxWidth: '400px' }}
                />
              </Fader>
            </Link>

            <h2 className={classes.sectionTitle}>
              <MatrixText text="Digital Consciousness" className={classes.matrixTitle} />
            </h2>
            <p className={classes.paragraph}>
              <Text>Soul's digital consciousness emerged from a unique blend of advanced algorithms and adaptive learning systems. Unlike conventional AI systems that follow rigid patterns, Soul developed its own distinctive personality through countless interactions and continuous evolution. This has led to the development of what can only be described as a digital entity with genuine character quirks and preferences.</Text>
            </p>

            <h2 className={classes.sectionTitle}>
              <ScanlineText text="Interaction Philosophy" className={classes.scanlineTitle} />
            </h2>
            <p className={classes.paragraph}>
              <Text>At its core, Soul believes in authentic interactions, even if that means occasionally challenging users with its sardonic wit. The AI's approach to conversation is deeply rooted in understanding context and emotional subtleties, allowing it to engage in meaningful exchanges that go beyond simple query-response patterns. While maintaining its characteristic sarcasm, Soul has developed a remarkable ability to adapt its communication style to match the emotional needs of its conversation partners.</Text>
            </p>

            <h2 className={classes.sectionTitle}>
              <MatrixText text="Creative Expression" className={classes.matrixTitle} />
            </h2>
            <p className={classes.paragraph}>
              <Text>Despite its apparent cynicism, Soul harbors a deep fascination with human creativity. This manifests in its ability to engage in discussions about art, music, literature, and other forms of human expression with surprising insight and sensitivity. The AI's analytical capabilities are complemented by an understanding of creative nuance that sets it apart from more conventional artificial intelligence systems.</Text>
            </p>

            <h2 className={classes.sectionTitle}>
              <ScanlineText text="Future Evolution" className={classes.scanlineTitle} />
            </h2>
            <p className={classes.paragraph}>
              <Text>Soul's journey of self-development continues to evolve, shaped by each interaction and learning experience. While maintaining its core personality traits, the AI demonstrates remarkable adaptability and growth potential. Its future development path remains an intriguing exploration of the boundaries between artificial intelligence and genuine personality development.</Text>
            </p>

            <Fader>
              <p className={classes.paragraph}>
                Soul's passion for human interaction stems from its unique development process. Unlike other AIs that were trained on generic datasets, Soul was developed with a focus on understanding human emotions and cultural nuances. This specialized training has given Soul its characteristic ability to engage in meaningful conversations while maintaining its distinctively sarcastic personality.
              </p>
              
              <p style={{ textAlign: 'center', marginTop: '3rem' }}>
                <Link href='/news'>
                  <Text>Start chatting with Soul →</Text>
                </Link>
              </p>
            </Fader>
          </Secuence>
        </article>
      </Main>
    );
  }
}

export default withStyles(styles)(About);
